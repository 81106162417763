import type { ChatNotifications } from "@/store/ChatStore";
import { useChatStore } from "@/store/ChatStore";
import type { FlagSocket } from "@/store/FlagSocketStore";
import { useFlagSocketStore } from "@/store/FlagSocketStore";
import type { MessageSocket } from "@/store/WebSocketStore";

import type {
  DsLiveNotificationsStats,
  DsLiveNotificationToast,
} from "@devsalsa/vue-core";
import { useDsLiveNotifierStore } from "@devsalsa/vue-core";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { ChatHandler } from "@/core/shared/helpers/Chat/ChatHandler";
import { MaintenanceHandler } from "@/core/shared/helpers/Maintenance/MaintenanceHandler";
import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { ChatMessageInfo } from "@/services/ChatService.types";

import NotificationRouterLink from "@/modules/notification/helpers/NotificationRouterLink";

import router from "@/router";

const APP_NOTIFICATION = "app_notification";
const CHAT_MESSAGE = "chat_message";
const MAINTENANCE = "maintenance";
const UPGRADING = "upgrading";
const HEARTBEAT = "heartbeat";
const AUTHENTICATED = "auth";
const PONG = "pong";
const USER_UPDATED = "user_updated";
const NEW_CHATS = "new_chats";
const NEW_APP_NOTIFICATIONS = "new_app_notifications";
const FLAG_UPDATED = "flag_updated";
const UPDATE_CHAT_MESSAGES = "update_chat_messages";

export class WebSocketStoreHandler {
  /**
   * Receives message from WebSocket, and store that message depending on type message.
   * @param message
   */
  static handle(message: MessageSocket): void {
    //If receives type message as "maintenance" will be set this state on ApiStore.
    //if not, always be reset this state and delete the notifier maintenance message
    if ([MAINTENANCE, UPGRADING].includes(message.type)) {
      MaintenanceHandler.setOn({ code: message.type } as ApiServiceError);
    } else {
      MaintenanceHandler.setOff();
    }

    //If receives type message as "app_notification" will be set this state on DsLiveNotifierStore.
    if (message.type === APP_NOTIFICATION) {
      const toast = message.data as DsLiveNotificationToast;
      const route = NotificationRouterLink.get(toast);
      toast.onClick = async () => {
        await router.push(route);
      };
      useDsLiveNotifierStore().addToast(toast);
    } else if (message.type === CHAT_MESSAGE) {
      //If receives type message as "chat_message" will be set this state on ChatStore.
      ChatHandler.storeMessage(message.data as ChatMessageInfo);
    } else if (message.type === USER_UPDATED) {
      //If receives type message as "user_updated" will be updated global account info.
      AccountHandler.getDebounceInfo();
    } else if (message.type === NEW_CHATS) {
      const data = message.data as ChatNotifications;
      useChatStore().setUnreadChats(data.unread_chats);
      useChatStore().setUnreadChatsMessages(data.chats);
    } else if (message.type === UPDATE_CHAT_MESSAGES) {
      const data = message.data as ChatNotifications;
      useChatStore().updateChatMessage(data.from_sequence_id);
    } else if (message.type === NEW_APP_NOTIFICATIONS) {
      const data = message.data as DsLiveNotificationsStats;
      useDsLiveNotifierStore().setUnread(data.unread_notifications);
    } else if (message.type === FLAG_UPDATED) {
      useFlagSocketStore().update(message.data as FlagSocket);
    } else if (![HEARTBEAT, AUTHENTICATED, PONG].includes(message.type)) {
      console.log("Web socket message NOT handled", message);
    }
  }
}
